var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "retain-focus": false,
        "no-click-animation": "",
        value: true,
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: false,
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-app-bar",
            { attrs: { color: "primary", dark: "", fixed: "" } },
            [
              _c(
                "v-container",
                { staticClass: "d-flex align-center pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { small: "", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c("v-toolbar-title", { staticClass: "mt-1" }, [
                    _c("div", [
                      _c(
                        "h1",
                        {
                          staticClass: "title font-weight-bold mb-1 title",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v("Dados Dependentes")]
                      ),
                      _c(
                        "h3",
                        {
                          staticClass: "caption ma-0",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v("Dados contratação")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }